<template>
  <div>
    <CustomTable :columns="columns" :rows="formattedRows" />
  </div>
</template>

<script>
import CustomTable from "@/components/customTable.vue";

export default {
  name: "ItemsPage",
  components: {
    CustomTable,
  },
  data() {
    return {
      columns: [
        "Categorie",
        "Type",
        "Nom",
        "Niveau",
        "Dernier Prix",
        "Prix Min",
        "Prix Max",
        "Prix Moyen",
      ],
      rows: [],
      loading: true,
    };
  },
  computed: {
    formattedRows() {
      return this.rows
        .map((row) => [
          row.categoryId,
          row.typeId,
          row.name, // Nom
          row.level, // Niveau
          row.currentPrice ? `${row.currentPrice} K` : "Non disponible", // Dernier Prix
          row.minPrice ? `${row.minPrice} K` : "Non disponible", // Prix Min
          row.maxPrice ? `${row.maxPrice} K` : "Non disponible", // Prix Max
          row.avgPrice ? `${row.avgPrice.toFixed(0)} K` : "Non disponible", // Prix Moyen
        ])
        .filter((row) => row[2] != "Non disponible")
        .filter((row) => row[2] != "0 K");
    },
  },
  async mounted() {
    try {
      // Fetching the items data and item prices data concurrently
      try {
        const response = await fetch("https://dofusapi.scuisond.com/items");
        this.rows = await response.json();
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
      this.loading = false;
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  },
};
</script>
