<template>
  <div
    class="mx-14 my-4 rounded-md w-auto mx-4 border border-4 border-yellow-500"
  >
    <input
      id="item-search"
      type="text"
      v-model="searchTerm"
      placeholder="Entrez le nom de l'objet à ajouter au favoris"
      class="border-gray-300 ring ring-yellow-500 focus:border-yellow-500 focus:ring focus:ring-yellow-200 rounded-sm shadow-sm px-4 py-2 w-full"
    />

    <!-- Suggestions d'autocomplétion -->
    <ul
      v-if="
        autocompleteValues &&
        autocompleteValues.length > 0 &&
        filteredItems.length > 1
      "
      class="absolute z-10 max-h-96 overflow-auto w-full bg-white border border-gray-300 mt-2 rounded-md shadow"
    >
      <li
        v-for="(item, index) in filteredItems"
        :key="index"
        @click="autocompleteClickFunction(item)"
        class="bg-gray-300 hover:bg-gray-100 shadow-sm px-4 py-2 w-full cursor-pointer"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    filteredItems() {
      return this.autocompleteValues.filter((item) =>
        typeof item === "string" && this.searchTerm.length > 1
          ? item.toLowerCase().includes(this.searchTerm.toLowerCase())
          : false
      );
    },
  },
  methods: {
    autocompleteClickFunction(item) {
      this.searchTerm = "";
      this.listClickFunction(item);
    },
  },
  props: {
    autocompleteValues: Array,
    listClickFunction: {
      type: Function,
      default: null,
    },
  },
};
</script>
