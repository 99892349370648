<template>
  <div class="container mx-auto bg-yellow-400 rounded-lg p-6 mt-10">
    <h1 class="text-2xl font-bold mb-4">Instructions</h1>
    <p class="mb-4">
      Pour obtenir les prix moyens des items Dofus, veuillez suivre les
      instructions suivantes :
    </p>
    <ol class="mb-4">
      <li>
        <p class="mb-2">Copiez le texte suivant :</p>
        <div class="relative">
          <input
            ref="textInput"
            type="text"
            readonly
            value="%AppData%\Dofus"
            class="border border-gray-300 rounded p-2 w-full pr-10 bg-gray-700 text-gray-200"
          />
          <button
            @click="copyText"
            class="absolute right-0 top-0 bottom-0 bg-transparent border-none p-0 m-0"
          >
            <img
              src="@/assets/icons/copy.svg"
              alt="Icône copier"
              class="w-8 rounded-md bg-gray-100 p-1 mr-2"
            />
          </button>
        </div>
      </li>
      <li>
        <p class="mb-2">
          Collez le texte copié dans la barre d'adresse de votre navigateur
          Windows.
        </p>
        <img
          src="@/assets/images/windows-explorer.png"
          alt="Image Explorateur Windows"
        />
      </li>
      <li>
        <p>Appuyez sur la touche `Enter`.</p>
      </li>
      <li>
        <p>
          Sélectionnez le fichier `itemAveragePrices.dat` pour envoyer les
          nouvelles données.
        </p>
      </li>
    </ol>
    <h2 class="text-2xl font-bold mb-4">Sélectionner le fichier</h2>
    <input
      type="file"
      accept=".dat,.json"
      class="border border-gray-300 rounded p-2"
      @change="handleFileChange"
    />
    <button
      @click="uploadFile"
      class="bg-yellow-600 text-white px-4 py-2 rounded ml-2"
    >
      Envoyer
    </button>

    <!-- Modal de confirmation -->
    <div v-if="showSuccessModal" class="fixed inset-0 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg">
        <h3 class="text-xl font-bold mb-2">Fichier envoyé avec succès !</h3>
        <p class="mb-4">Le fichier a été envoyé avec succès.</p>
        <button @click="redirectToFavorites" class="bg-green-500 text-white px-4 py-2 rounded">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RefreshPage',
  data() {
    return {
      selectedFile: null,
      showSuccessModal: false
    }
  },
  methods: {
    async copyText() {
      const input = this.$refs.textInput
      try {
        await navigator.clipboard.writeText(input.value)
      } catch (err) {
        console.error('Unable to copy text: ', err)
      }
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0]
    },
    async uploadFile() {
      try {
        const formData = new FormData()
        formData.append('file', this.selectedFile)
        await fetch(
          window.location.host == 'dofus.scuisond.com'
            ? 'https://dofusapi.scuisond.com/upload'
            : 'http://localhost:3000/upload',
          {
            method: 'POST',
            body: formData
          }
        )
        console.log('Fichier envoyé avec succès')
        this.showSuccessModal = true; // Afficher le modal de confirmation
      } catch (error) {
        console.error("Erreur lors de l'envoi du fichier :", error)
      }
    },
    redirectToFavorites() {
      this.showSuccessModal = false; // Cacher le modal
      this.$router.push('/favorites'); // Redirection vers /favorites
    }
  }
}
</script>
