<template>
  <div
    class="container mx-auto px-4 py-3 border border-2 border-yellow-500 rounded-lg mt-10"
  >
    <h1 class="text-2xl font-bold mb-4 text-gray-100">Connexion</h1>
    <form @submit.prevent="login">
      <div class="mb-4">
        <label
          class="block text-gray-100 text-sm font-bold mb-2"
          for="username"
        >
          Nom d'utilisateur
        </label>
        <input
          v-model="username"
          type="text"
          id="username"
          name="username"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div class="mb-6">
        <label
          class="block text-gray-100 text-sm font-bold mb-2"
          for="password"
        >
          Mot de passe
        </label>
        <input
          v-model="password"
          type="password"
          id="password"
          name="password"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div class="flex items-center justify-between">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Connexion
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import sha256 from "crypto-js/sha256"; // Importer la fonction de hachage SHA-256 de crypto-js
import { setAuth, setAuthUser } from "../router";

export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async login() {
      // Hasher le mot de passe avant de l'envoyer à l'API
      const hashedPassword = sha256(this.password).toString();
      // Effectuer la requête HTTP POST vers l'API d'authentification avec le mot de passe haché
      axios
        .post("https://dofusapi.scuisond.com/auth", {
          username: this.username,
          password: hashedPassword,
        })
        .then((response) => {
          if (response.data.success) {
            setAuth(true);
            setAuthUser(this.username);
            this.$router.push("/favorites");
            // Rediriger ou effectuer d'autres actions après une connexion réussie
          } else {
            alert("Identifiants incorrects!");
          }
        })
        .catch((error) => {
          console.error("Erreur:", error);
          alert("Une erreur s'est produite lors de la connexion.");
        });
    },
  },
};
</script>

<style>
/* Ajoutez vos styles CSS ici */
</style>
