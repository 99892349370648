<template>
  <div>
    <SingleInput
      :autocompleteValues="updatedNames"
      :listClickFunction="addFavorite"
    />
    <div v-if="!showDetail">
      <button
        @click="showDetail = true"
        class="bg-gray-100 mx-14 px-3 py-1 border-2 w-32 border-yellow-500 rounded-md"
      >
        Vue simple
      </button>
      <OneLevelTable
        :columnsHeader="columns"
        :rows="formattedRows"
        :deleteFunction="deleteFavorite"
      />
    </div>
    <div v-else>
      <button
        @click="showDetail = false"
        class="bg-yellow-400 mx-14 px-3 py-1 border-2 w-32 border-gray-100 rounded-md"
      >
        Vue détaillée
      </button>
      <TwoLevelTable
        :columnsHeader="columns"
        :rows="formattedRows"
        :subRows="subRows"
        :deleteFunction="deleteFavorite"
      />
    </div>
  </div>
</template>

<script>
import OneLevelTable from "./oneLevelTable.vue";
import TwoLevelTable from "./twoLevelTable.vue"; // Assurez-vous d'importer le composant TwoLevelTable
import SingleInput from "./singleInput.vue";
import { getAuthUser } from "@/router";
import axios from "axios";

export default {
  name: "FavoritesPage",
  components: {
    OneLevelTable,
    TwoLevelTable,
    SingleInput,
  },
  data() {
    return {
      columns: [
        "Categorie",
        "Type",
        "Nom",
        "Niveau",
        "Prix",
        "Prix Moyen",
        "Prix Craft",
        "Benef",
        "Pourcentage",
        "Prix Craft Combiné",
        "Benef Combiné",
        "Pourcentage Combiné",
      ],
      rows: [],
      loading: true,
      searchTerm: "",
      items: [],
      showDetail: false,
      subRows: [],
    };
  },
  computed: {
    formattedRows() {
      return this.rows
        .filter((row) => row.currentPrice !== "Non disponible")
        .filter((row) => row.currentCraftPrice !== "Non disponible")
        .map((row) => [
          row.itemId,
          row.name, // Nom
          row.level, // Niveau
          row.currentPrice
            ? `${row.currentPrice.toLocaleString()} K`
            : "Non disponible", // Dernier Prix
          row.avgPrice
            ? `${row.avgPrice.toFixed(0).toLocaleString()} K`
            : "Non disponible", // Prix Moyen
          row.currentCraftPrice
            ? `${row.currentCraftPrice.toLocaleString()} K`
            : "Non disponible", // Prix Craft
          row.benef ? `${row.benef.toLocaleString()} K` : "0 K", // Benef
          row.percentage
            ? row.percentage.toFixed(2).toLocaleString() + "%" // Pourcentage
            : "Non disponible",
          row.currentCombinedCraftPrice
            ? `${row.currentCombinedCraftPrice.toLocaleString()} K`
            : "Non disponible", // Prix Craft
          row.benefCombined ? `${row.benefCombined.toLocaleString()} K` : "0 K", // Benef
          row.percentageCombined
            ? row.percentageCombined.toFixed(2).toLocaleString() + "%" // Pourcentage
            : "Non disponible",
        ]); // Filtrer les lignes avec un prix craft disponible
    },
    updatedNames() {
      return this.items.map((item) => item.name);
    },
  },
  methods: {
    async updateSubrows() {
      try {
        // Itérer à travers chaque élément de rows
        for (const item of this.rows) {
          const itemId = item.itemId;
          // Faire une requête pour récupérer les subrows pour cet itemId
          const response = await axios.get(
            `https://dofusapi.scuisond.com/subrow/${itemId}`
          );
          if (this.subRows[itemId] === undefined) {
            this.subRows[itemId] = [];
          }
          this.subRows[itemId].push(
            response.data.map((row) => [
              row.itemId,
              row.name, // Nom
              row.level, // Niveau
              row.currentPrice
                ? `${row.currentPrice.toLocaleString()} K`
                : "Non disponible", // Dernier Prix
              row.currentAveragePrice
                ? `${row.currentAveragePrice.toFixed(0).toLocaleString()} K`
                : "Non disponible", // Prix Moyen
              row.currentCraftPrice
                ? `${row.currentCraftPrice.toLocaleString()} K`
                : "Non disponible", // Prix Craft
              row.profit
                ? `${row.profit.toLocaleString()} K`
                : "Non disponible", // Benef
              row.profitPercentage
                ? row.profitPercentage.toFixed(2).toLocaleString() + "%" // Pourcentage
                : "Non disponible",
            ])
          );
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour des sous-lignes :", error);
      }
    },
    async deleteFavorite(itemId) {
      try {
        // Supprimer l'élément avec l'ID spécifié
        await axios.delete(
          "https://dofusapi.scuisond.com/favorites/" +
            getAuthUser() +
            "/" +
            itemId
        );
        // Mettre à jour les données après la suppression de l'élément
        await this.updateData();
      } catch (error) {
        console.error("Erreur lors de la suppression de l'élément :", error);
      }
    },
    async fetchItemNames() {
      try {
        const response = await fetch("https://dofusapi.scuisond.com/itemNames");
        this.items = await response.json();
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    },
    async addFavorite(itemName) {
      let index = 0;

      for (const item of this.items) {
        if (item.name == itemName) {
          break;
        }
        index++;
      }
      try {
        // Ajouter un favori à la liste
        await axios.post(
          "https://dofusapi.scuisond.com/favorites/" +
            getAuthUser() +
            "/" +
            this.items[index].itemId
        );
        // Mettre à jour les données après l'ajout d'un favori
        await this.updateData();
      } catch (error) {
        console.error("Erreur lors de l'ajout du favori :", error);
      }
    },
    async updateData() {
      try {
        // Fetching the items data, prices data, and recipes data concurrently
        try {
          const response = await fetch(
            "https://dofusapi.scuisond.com/favorites/" + getAuthUser()
          );
          const mainData = await response.json();

          // Mettre à jour la structure de rows pour contenir les sous-lignes
          this.rows = mainData.map((item) => ({
            ...item,
            subrows: [], // Initialiser avec un tableau vide pour les sous-lignes
          }));
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
        this.loading = false;
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    },
  },
  async mounted() {
    await this.updateData();
    await this.fetchItemNames();
    await this.updateSubrows(); // Appel pour mettre à jour les sous-lignes
  },
};
</script>
