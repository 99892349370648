<template>
  <div class="bg-gray-600 text-gray-100 p-6">
    <h1 class="text-3xl font-bold mb-4">Liste des Archimonstres</h1>
    <div class="flex mb-4">
      <input
        v-model="nouveauNom"
        list="nomsListe"
        type="text"
        placeholder="Nom"
        class="border border-yellow-500 px-2 py-1 rounded mr-2 bg-gray-100 text-gray-600"
      />
      <datalist id="nomsListe">
        <option
          v-for="archi in archisNoms"
          :value="archi.archiName"
          :key="archi.archiId"
        ></option>
      </datalist>
      <input
        v-model="nouveauPrix"
        type="number"
        placeholder="Prix"
        class="border border-yellow-500 px-2 py-1 rounded mr-2 bg-gray-100 text-gray-600"
      />
      <button
        @click="ajouterArchimonstre"
        class="bg-yellow-500 hover:bg-yellow-400 text-gray-100 px-2 py-1 rounded"
      >
        Ajouter
      </button>
    </div>
    <table class="w-full">
      <thead>
        <tr>
          <th class="border border-yellow-500 px-4 py-2">Nom</th>
          <th class="border border-yellow-500 px-4 py-2">Prix Moyen</th>
          <th class="border border-yellow-500 px-4 py-2">Prix Actuel</th>
          <th class="border border-yellow-500 px-4 py-2">Nombre Possédé</th>
          <th class="border border-yellow-500 px-4 py-2">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="archimonstre in archimonstres"
          :key="archimonstre.archiId"
          :class="{
            'bg-green-200 text-gray-700':
              archimonstre.archiCurrentPrice <
              archimonstre.archiAveragePrice * 0.75,
            'bg-yellow-200 text-gray-700':
              archimonstre.archiCurrentPrice >
              archimonstre.archiAveragePrice * 1.8,
          }"
        >
          <td class="border border-yellow-500 px-4 py-2">
            {{ archimonstre.archiName }}
          </td>
          <td class="border border-yellow-500 px-4 py-2">
            {{ archimonstre.archiAveragePrice }}
          </td>
          <td class="border border-yellow-500 px-4 py-2">
            {{ archimonstre.archiCurrentPrice }}
          </td>
          <td class="border border-yellow-500 px-4 py-2">
            {{ archimonstre.archiNumber }}
          </td>
          <td class="border border-yellow-500 px-4 py-2">
            <button
              @click="acheter(archimonstre)"
              class="bg-green-500 hover:bg-green-400 text-gray-100 px-2 py-1 rounded mr-2"
            >
              Achat
            </button>
            <input
              v-model="archimonstre.nouveauPrix"
              type="number"
              placeholder="Nouveau prix d'achat"
              class="border border-yellow-500 px-2 py-1 rounded mr-2 bg-gray-100 text-gray-600"
            />
            <button
              @click="vendre(archimonstre)"
              class="bg-yellow-500 hover:bg-yellow-400 text-gray-100 px-2 py-1 rounded"
            >
              Vente
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getAuthUser } from "@/router";

export default {
  data() {
    return {
      archimonstres: [],
      archisNoms: [],
      nouveauNom: "",
      nouveauPrix: "",
    };
  },
  methods: {
    async fetchArchisNoms() {
      try {
        const response = await fetch("https://dofusapi.scuisond.com/archis");
        const data = await response.json();
        this.archisNoms = data;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des noms des archis:",
          error
        );
      }
    },
    async fetchArchimonstres() {
      try {
        const username = getAuthUser();
        const response = await fetch(
          `https://dofusapi.scuisond.com/archis/${username}`
        );
        const data = await response.json();
        this.archimonstres = data;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des archimonstres:",
          error
        );
      }
    },
    async acheter(archimonstre) {
      try {
        if (archimonstre.nouveauPrix !== null && archimonstre.nouveauPrix > 0) {
          const username = getAuthUser();
          await fetch(
            `https://dofusapi.scuisond.com/archis/${username}/${archimonstre.archiId}/${archimonstre.nouveauPrix}`,
            {
              method: "POST",
            }
          );
          console.log(
            `Achat de ${archimonstre.archiName} effectué avec succès.`
          );
          this.fetchArchimonstres();
        } else {
          alert("Veuillez entrer un prix valide.");
        }
      } catch (error) {
        console.error("Erreur lors de l'achat de l'archimonstre:", error);
      }
    },
    async vendre(archimonstre) {
      try {
        const username = getAuthUser();
        await fetch(
          `https://dofusapi.scuisond.com/archis/${username}/${archimonstre.archiId}`,
          {
            method: "DELETE",
          }
        );
        console.log(
          `Vente de ${archimonstre.archiName} effectuée avec succès.`
        );
        this.fetchArchimonstres();
      } catch (error) {
        console.error("Erreur lors de la vente de l'archimonstre:", error);
      }
    },
    async ajouterArchimonstre() {
      try {
        if (this.nouveauNom && this.nouveauPrix) {
          const archi = this.archisNoms.find(
            (archi) => archi.archiName === this.nouveauNom
          );
          if (archi) {
            const username = getAuthUser();
            await fetch(
              `https://dofusapi.scuisond.com/archis/${username}/${archi.archiId}/${this.nouveauPrix}`,
              {
                method: "POST",
              }
            );
            console.log("Archimonstre ajouté avec succès.");
            this.fetchArchimonstres();
            this.nouveauNom = "";
            this.nouveauPrix = "";
          } else {
            alert("Nom d'archimonstre invalide.");
          }
        } else {
          alert("Veuillez remplir tous les champs.");
        }
      } catch (error) {
        console.error("Erreur lors de l'ajout de l'archimonstre:", error);
      }
    },
  },
  mounted() {
    this.fetchArchisNoms();
    this.fetchArchimonstres();
  },
};
</script>
