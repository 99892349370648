<template>
  <div
    class="mx-14 my-4 overflow-x-auto max-h-[75vh] rounded-md w-auto mx-4 border border-4 border-yellow-500"
  >
    <table
      class="w-full max-h-[70vh] text-sm text-left text-gray-500 divide-x-2"
    >
      <thead class="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          <!-- Générer dynamiquement les en-têtes de colonne avec boutons de filtre -->
          <template v-for="(column, index) in columnsHeader" :key="index">
            <th
              v-if="index >= 2"
              :class="{
                'px-6 py-3 relative bg-gray-100': index % 2 === 0,
                'px-6 py-3 relative bg-gray-300': index % 2 !== 0,
              }"
            >
              {{ column }}
              <button
                @click="sortByColumn(index - 1)"
                class="ml-2 text-gray-400 absolute right-10 top-0 bottom-0 flex items-center"
              >
                <svg
                  v-if="sortColumn === index - 1 && sortDirection === 'asc'"
                  class="h-4 w-4 inline"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <svg
                  v-else
                  class="h-4 w-4 inline"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </th>
          </template>
          <th
            v-if="deleteFunction !== null"
            :key="'delete'"
            :class="'px-6 py-3 relative bg-gray-100'"
          ></th>
          <th
            v-if="showFunction !== null"
            :key="'show'"
            :class="'px-6 py-3 relative bg-gray-300'"
          ></th>
          <th
            v-if="updateFunction !== null"
            :key="'update'"
            :class="'px-6 py-3 relative bg-gray-100'"
          ></th>
        </tr>
      </thead>
      <tbody>
        <!-- Générer dynamiquement les lignes à partir des données triées -->
        <tr
          v-for="(row, rowIndex) in sortedRows"
          :key="rowIndex"
          class="bg-white border-b group-hover:bg-yellow-500 group-hover:text-black group"
          style="transition: background-color 0.3s, color 0.3s"
        >
          <template v-for="(cell, cellIndex) in row" :key="cellIndex">
            <td
              v-if="cellIndex > 0"
              :class="{
                'px-6 py-4 font-medium text-gray-900 bg-white group-hover:bg-yellow-500 group-hover:text-black':
                  cellIndex % 2 !== 0,
                'px-6 py-4 font-medium text-gray-900 bg-gray-200 group-hover:bg-yellow-500 group-hover:text-black':
                  cellIndex % 2 === 0,
              }"
            >
              <div
                class="text-ellipsis overflow-hidden whitespace-nowrap max-w-64"
              >
                {{ cell }}
              </div>
            </td>
          </template>
          <td
            v-if="deleteFunction !== null"
            class="group-hover:bg-yellow-500 group-hover:text-black"
          >
            <div
              class="text-ellipsis overflow-hidden whitespace-nowrap max-w-64"
            >
              <button
                @click="deleteFunction(row[0])"
                class="text-red-500 w-full"
              >
                Supprimer
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  computed: {
    sortedRows() {
      if (
        this.sortColumn !== null &&
        ["asc", "desc"].includes(this.sortDirection)
      ) {
        const rowsCopy = [...this.rows];

        rowsCopy.sort((a, b) => {
          const valueA = this.parseValue(a[this.sortColumn]);
          const valueB = this.parseValue(b[this.sortColumn]);

          if (!isNaN(valueA) && !isNaN(valueB)) {
            // Si les deux valeurs sont des nombres, comparez simplement les valeurs
            if (this.sortDirection === "asc") {
              return valueA - valueB;
            } else {
              return valueB - valueA;
            }
          } else if (typeof valueA === "string" && typeof valueB === "string") {
            // Si les deux valeurs sont des chaînes de caractères, utilisez localeCompare
            if (this.sortDirection === "asc") {
              return valueA.localeCompare(valueB);
            } else {
              return valueB.localeCompare(valueA);
            }
          } else {
            // Si les types sont différents, mettez les nombres en premier puis les chaînes de caractères
            if (!isNaN(valueA)) {
              return -1;
            } else if (!isNaN(valueB)) {
              return 1;
            } else {
              // Gérer le cas où une des valeurs est null ou undefined
              return 0; // Aucun changement d'ordre
            }
          }
        });

        return rowsCopy;
      } else {
        return this.rows;
      }
    },
  },
  props: {
    columnsHeader: Array,
    rows: Array,
    deleteFunction: {
      type: Function,
      default: null,
    },
    showFunction: {
      type: Function,
      default: null,
    },
    updateFunction: {
      type: Function,
      default: null,
    },
    addFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      sortColumn: null,
      sortDirection: "asc",
      searchQuery: "",
      benefQuery: "",
      categories: [],
      types: [],
      selectedCategory: -1,
      selectedType: -1,
    };
  },
  methods: {
    parseValue(value) {
      // Convertir les valeurs de prix et de pourcentage en nombres
      if (typeof value === "string") {
        if (value.includes("K")) {
          return parseFloat(value.replace(/[^\d.-]/g, "").replace("K", ""));
        } else if (value.includes("%")) {
          return parseFloat(value.replace(/[^\d.-]/g, "").replace("%", ""));
        } else {
          if (isNaN(value)) {
            return value;
          } else {
            return parseFloat(value);
          }
        }
      }
      return value;
    },
    sortByColumn(columnIndex) {
      if (this.sortColumn === columnIndex) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnIndex;
        this.sortDirection = "asc";
      }
    },
    async deleteRow(param) {
      // Mettre à jour les données après la suppression de l'élément
      this.$emit("delete-function", param);
    },
    async updateRow(param) {
      // Mettre à jour les données après la suppression de l'élément
      this.$emit("update-function", param);
    },
    async showRow(param) {
      // Mettre à jour les données après la suppression de l'élément
      this.$emit("show-function", param);
    },
    async addRow(param) {
      // Mettre à jour les données après la suppression de l'élément
      this.$emit("add-function", param);
    },
  },
};
</script>
