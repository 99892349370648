import { createRouter, createWebHashHistory } from "vue-router";
import ItemsPage from "./components/items.vue";
import RecipesPage from "./components/recipes.vue";
import EvolutionPage from "./components/evolution.vue";
import RefreshPage from "./components/refresh.vue";
import LoginPage from "./components/login.vue";
import FavoritesPage from "./components/favorites.vue";
import ArchisPage from "./components/archiPage.vue";
import ArchiStockPage from "./components/archiStockPage.vue";

const routes = [
  { path: "/", redirect: "/recipes" },
  { path: "/items", component: ItemsPage },
  { path: "/recipes", component: RecipesPage },
  { path: "/evolution", component: EvolutionPage },
  { path: "/refresh", component: RefreshPage },
  { path: "/login", component: LoginPage },
  { path: "/favorites", component: FavoritesPage },
  { path: "/archis", component: ArchisPage },
  { path: "/archistock", component: ArchiStockPage },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

let isAuthenticated = false; // Initialiser l'état d'authentification à false
let authUser = "";

router.beforeEach((to, from, next) => {
  if (to.path !== "/login" && !isAuthenticated) {
    next("/login"); // Rediriger vers la page de connexion si non authentifié
  } else {
    next(); // Continuer la navigation normalement
  }
});

export function setAuth(value) {
  isAuthenticated = value; // Mettre à jour l'état d'authentification
}

export function setAuthUser(value) {
  authUser = value; // Mettre à jour l'état d'authentification
}

export function isAuth() {
  return isAuthenticated; // Retourner l'état d'authentification
}

export function getAuthUser() {
  return authUser; // Retourner l'état d'authentification
}

export default router;
