<template>
  <div>
    <CustomTable :columns="columns" :rows="formattedRows" />
  </div>
</template>

<script>
import CustomTable from "@/components/customTable.vue";

export default {
  name: "ItemsPage",
  components: {
    CustomTable,
  },
  data() {
    return {
      columns: [
        "Categorie",
        "Type",
        "Nom",
        "Niveau",
        "Prix",
        "Prix Moyen",
        "Prix Craft",
        "Benef",
        "Pourcentage",
        "Prix Craft Combiné",
        "Benef Combiné",
        "Pourcentage Combiné",
      ],
      rows: [],
      loading: true,
    };
  },
  computed: {
    formattedRows() {
      return this.rows
        .map((row) => [
          row.categoryId,
          row.typeId,
          row.name, // Nom
          row.level, // Niveau
          row.currentPrice ? `${row.currentPrice} K` : "Non disponible", // Dernier Prix
          row.avgPrice ? `${row.avgPrice.toFixed(0)} K` : "Non disponible", // Prix Moyen
          row.currentCraftPrice
            ? `${row.currentCraftPrice} K`
            : "Non disponible", // Prix Craft
          row.benef ? `${row.benef} K` : "0 K", // Benef
          row.percentage
            ? row.percentage.toFixed(2) + "%" // Pourcentage
            : "Non disponible",
          row.currentCombinedCraftPrice
            ? `${row.currentCombinedCraftPrice} K`
            : "Non disponible", // Prix Craft
          row.benefCombined ? `${row.benefCombined} K` : "0 K", // Benef
          row.percentageCombined
            ? row.percentageCombined.toFixed(2) + "%" // Pourcentage
            : "Non disponible",
        ])
        .filter((row) => row[2] !== "Non disponible")
        .filter((row) => row[4] !== "Non disponible"); // Filtrer les lignes avec un prix craft disponible
    },
  },
  async mounted() {
    try {
      // Fetching the items data, prices data, and recipes data concurrently
      try {
        const response = await fetch("https://dofusapi.scuisond.com/recipes");
        this.rows = await response.json();
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
      this.loading = false;
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  },
};
</script>
