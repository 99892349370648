<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'

// Enregistrer tous les plugins et échelles
Chart.register(...registerables)

export default {
  props: {
    title: String,
    labels: Array,
    data: Array
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const chartCanvas = this.$refs.chartCanvas
      if (!chartCanvas) return // Vérifier si la référence est définie

      const ctx = chartCanvas.getContext('2d')

      new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.title,
              data: this.data,
              backgroundColor: 'rgba(255, 100, 0, 0.5)',
              borderColor: 'rgba(255, 215, 0, 1)',
              borderWidth: 2
            }
          ]
        },
        options: {
          plugins: {
            legend: {
              display: true,
              labels: {
                color: '#EEEEEE'
              }
            }
          },
          scales: {
            x: {
              type: 'time', // Utiliser l'échelle de temps
              time: {
                unit: 'day'
              },
              grid: {
                color: '#888888'
              },
              ticks: { color: 'white' }
            },
            y: {
              ticks: {
                callback: function (value) {
                  return value + ' K'
                },
                color: 'white'
              },
              grid: {
                color: '#EEEEEE'
              }
            }
          }
        }
      })
    }
  }
}
</script>
