<template>
  <div class="container mx-auto mt-4">
    <!-- Barre de recherche avec autocomplete -->
    <div class="relative">
      <input
        id="item-search"
        type="text"
        v-model="searchTerm"
        placeholder="Entrez le nom de l'objet"
        class="border-gray-300 ring ring-yellow-500 focus:border-yellow-500 focus:ring focus:ring-yellow-200 rounded-md shadow-sm px-4 py-2 w-full"
      />
      <!-- Suggestions d'autocomplétion -->
      <ul
        v-if="filteredItems.length > 1"
        class="absolute z-10 max-h-96 overflow-auto w-full bg-white border border-gray-300 mt-2 rounded-md shadow"
      >
        <li
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="fillInput(item)"
          class="bg-gray-300 hover:bg-gray-100 shadow-sm px-4 py-2 w-full cursor-pointer"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <!-- Graphique -->
    <custom-graph
      v-if="selectedItem && selectedItemPrices.length > 0"
      :key="selectedItem.itemId"
      :title="'Prix de ' + selectedItem.name"
      :labels="selectedItemPrices.map((item) => item.date)"
      :data="selectedItemPrices.map((item) => item.price)"
      class="mt-4 border border-2 border-yellow-500 rounded-md p-4 w-[100%]"
    />
  </div>
</template>

<script>
import CustomGraph from "./customGraph.vue";

export default {
  name: "EvolutionPage",
  components: {
    CustomGraph,
  },
  data() {
    return {
      searchTerm: "",
      items: [],
      selectedItem: null,
      selectedItemPrices: [],
    };
  },
  methods: {
    async fetchItemNames() {
      try {
        const response = await fetch("https://dofusapi.scuisond.com/itemNames");
        this.items = await response.json();
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    },
    async fillInput(item) {
      this.searchTerm = item.name;
      this.selectedItem = item;
      this.selectedItemPrices = []; // Réinitialiser les prix à une liste vide
      await this.fetchItemPrices(item.itemId);
    },
    async fetchItemPrices(itemId) {
      try {
        const response = await fetch(
          `https://dofusapi.scuisond.com/itemPrices/${itemId}`
        );
        this.selectedItemPrices = await response.json();
      } catch (error) {
        console.error("Erreur lors de la récupération des prix :", error);
      }
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) =>
        this.searchTerm.length > 4
          ? item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
          : false
      );
    },
  },
  mounted() {
    this.fetchItemNames();
  },
};
</script>
