<template>
  <div class="overflow-hidden">
    <div class="flex items-center">
      <select
        v-model="selectedStep"
        id="select1"
        class="border-2 border-yellow-500 rounded-md w-full py-2 mx-10 my-5 px-2"
      >
        <option v-for="step in steps" :value="step" :key="step">
          {{ step }}
        </option>
      </select>

      <button
        @click="selectSuivant()"
        class="border-2 border-yellow-500 bg-gray-200 rounded-md py-2 mr-5 px-2 w-32"
      >
        Suivant
      </button>
      <button
        @click="selectPrecedent()"
        class="border-2 border-yellow-500 bg-gray-200 rounded-md py-2 mr-5 px-2 w-32"
      >
        Précédent
      </button>
    </div>

    <div class="flex flex-wrap">
      <div
        v-for="(column, index) in columns"
        :key="index"
        class="flex flex-col items-center justify-center bg-gray-600 text-yellow-500 w-full md:w-1/2"
      >
        <ul>
          <li class="mb-2 flex">
            <h2
              class="w-10 border-yellow-500 border rounded-l-md text-gray-100 p-2"
            ></h2>
            <h1
              class="w-48 border-yellow-500 border text-gray-100 p-2 border-collapse font-bold truncate"
            >
              Nom archi
            </h1>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              Prix actuel
            </h2>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              Prix mini
            </h2>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              Prix moyen
            </h2>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              Prix maxi
            </h2>
            <h2
              class="w-32 border-yellow-500 border rounded-r-md text-gray-100 p-2 mr-2"
            ></h2>
          </li>
        </ul>
        <ul>
          <li v-for="archi in column" :key="archi.archiId" class="mb-2 flex">
            <button
              @click="copyText(archi.archiName)"
              class="bg-transparent border-none p-0 m-0"
            >
              <img
                src="@/assets/icons/copy.svg"
                alt="Icône copier"
                class="w-8 rounded-md bg-gray-100 p-1 mr-2"
              />
            </button>
            <h1
              class="w-48 rounded-l-md border-yellow-500 border text-gray-100 p-2 border-collapse font-bold truncate"
            >
              {{ archi.archiName }}
            </h1>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              {{ archi.archiCurrentPrice.toLocaleString() }} K
            </h2>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              {{ archi.minPrice.toLocaleString() }} K
            </h2>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              {{ archi.avgPrice.toLocaleString() }} K
            </h2>
            <h2 class="w-32 border-yellow-500 border text-gray-100 p-2">
              {{ archi.maxPrice.toLocaleString() }} K
            </h2>
            <input
              type="number"
              v-model="archi.newPrice"
              @change="updatePrice(archi.archiId, $event)"
              :class="{ 'bg-green-400': isToday(archi.updateDate) }"
              class="w-32 border-2 border-yellow-500 rounded-r-md px-2 mr-2"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      archis: [],
      selectedStep: 20,
      steps: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34], // Assuming steps are 1, 2, 3
      columns: [],
    };
  },
  computed: {
    filteredArchis() {
      return this.archis.filter(
        (archi) => archi.archiStep === this.selectedStep
      );
    },
    splitArchis() {
      const columnCount = 2; // Number of columns
      const archisCopy = [...this.filteredArchis];
      const columns = Array.from({ length: columnCount }, () => []);
      let colIndex = 0;

      archisCopy.forEach((archi) => {
        columns[colIndex].push(archi);
        colIndex = (colIndex + 1) % columnCount;
      });

      return columns;
    },
  },
  methods: {
    async selectSuivant() {
      if (this.steps.includes(this.selectedStep + 1)) {
        this.selectedStep = this.selectedStep + 1; // Mettre à jour la valeur sélectionnée
        await this.fetchArchis(); // Attendre que le fetch soit terminé
      }
    },
    async selectPrecedent() {
      if (this.steps.includes(this.selectedStep - 1)) {
        this.selectedStep = this.selectedStep - 1; // Mettre à jour la valeur sélectionnée
        await this.fetchArchis(); // Attendre que le fetch soit terminé
      }
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error("Unable to copy text: ", err);
      }
    },
    fetchArchis() {
      fetch("https://dofusapi.scuisond.com/archis")
        .then((response) => response.json())
        .then((data) => {
          this.archis = data;
        });
    },
    isToday(date) {
      date = date.split("T")[0];
      let today = new Date();
      today.setDate(today.getDate() - 1);
      today = today.toISOString().split("T")[0];
      return date === today;
    },
    updatePrice(archiId, event) {
      const archi = this.archis.find((a) => a.archiId === archiId);
      fetch(`https://dofusapi.scuisond.com/archis/${archiId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPrice: archi.newPrice }),
      }).then(() => {
        this.fetchArchis();
        // Ajouter la classe à l'élément qui a déclenché l'événement
        event.target.classList.add("bg-green-400");
      });
    },
  },
  mounted() {
    this.fetchArchis();
  },
  watch: {
    // Update columns when filteredArchis changes
    filteredArchis: {
      handler() {
        this.columns = this.splitArchis;
      },
      immediate: true,
    },
  },
};
</script>
