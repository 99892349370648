<template>
  <div
    :class="{
      'w-screen h-screen overflow-hidden bg-gray-600': !isArchistockRoute,
    }"
  >
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {
      isArchistockRoute: false,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      // Vérifie si la route actuelle est "archistock"
      this.isArchistockRoute = to.path === "/archistock";
      next();
    });
  },
};
</script>
