<template>
  <nav class="bg-gray-800">
    <div class="container mx-auto px-4 py-3">
      <div class="flex justify-between items-center">
        <div class="flex">
          <img src="../assets/icons/kamas-50.png" class="h-7 mr-4" />
          <h1 class="text-yellow-400 text-xl font-bold">Dofus Price Tool</h1>
          <img src="../assets/icons/kamas-50.png" class="h-7 ml-4" />
        </div>
        <div class="flex space-x-4">
          <div
            class="relative"
            @mouseover="showDropdown('prix')"
            @mouseleave="hideDropdown('prix')"
          >
            <span class="text-white hover:text-gray-300 cursor-pointer"
              >Prix</span
            >
            <ul v-show="showMenu.prix" class="dropdown">
              <li>
                <router-link class="text-white hover:text-gray-300" to="/items"
                  >Items</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-white hover:text-gray-300"
                  to="/recipes"
                  >Recettes</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-white hover:text-gray-300"
                  to="/evolution"
                  >Evolution</router-link
                >
              </li>
            </ul>
          </div>
          <div
            class="relative"
            @mouseover="showDropdown('archis')"
            @mouseleave="hideDropdown('archis')"
          >
            <span class="text-white hover:text-gray-300 cursor-pointer"
              >Archis</span
            >
            <ul v-show="showMenu.archis" class="dropdown">
              <li>
                <router-link class="text-white hover:text-gray-300" to="/archis"
                  >Archi</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-white hover:text-gray-300"
                  to="/archistock"
                  >ArchiStock</router-link
                >
              </li>
            </ul>
          </div>
          <router-link
            v-if="isAuthenticated"
            class="text-white hover:text-gray-300"
            to="/favorites"
            >Favoris</router-link
          >
          <router-link
            v-if="isAuthenticated"
            class="text-white hover:text-gray-300"
            to="/refresh"
            >Refresh</router-link
          >
        </div>
      </div>
    </div>
  </nav>
  <div
    class="absolute bottom-6 right-6 h-28 border border-4 z-10 hover:opacity-30 border-yellow-600 rounded-xl"
  >
    <img
      src="../assets/images/Approuved.png"
      class="rounded-md h-full hover:opacity-30"
    />
  </div>
</template>

<script>
import { isAuth } from "../router"; // Importer la fonction isAuth depuis votre routeur

export default {
  name: "NavbarComponent",
  data() {
    return {
      isAuthenticated: false, // Initialiser l'état d'authentification à false
      showMenu: {
        prix: false,
        archis: false,
      },
    };
  },
  watch: {
    $route() {
      // Mettre à jour l'état d'authentification à chaque changement de route
      this.isAuthenticated = isAuth();
    },
  },
  created() {
    // Mettre à jour l'état d'authentification lors de la création du composant
    this.isAuthenticated = isAuth();
  },
  methods: {
    showDropdown(menu) {
      this.showMenu[menu] = true;
    },
    hideDropdown(menu) {
      this.showMenu[menu] = false;
    },
  },
};
</script>

<style>
.dropdown {
  position: absolute;
  display: none;
  background-color: #1f2937;
  min-width: 120px;
  z-index: 1;
  padding: 0;
  list-style-type: none;
  top: 100%;
  left: 0;
}

.dropdown li {
  padding: 10px;
}

.dropdown li:hover {
  background-color: #4b5563;
}

.relative:hover .dropdown {
  display: block;
}
</style>
