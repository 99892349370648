<template>
  <div class="overflow-hidden h-screen w-auto mx-10">
    <!-- Barre de recherche -->
    <div class="flex items-center justify-between mx-4 my-4">
      <input
        type="text"
        v-model="searchQuery"
        class="border-yellow-500 border border-4 rounded-md shadow-sm px-4 py-2 w-full m-2"
        placeholder="Rechercher un nom..."
      />
      <input
        type="text"
        v-model="benefQuery"
        class="border-yellow-500 border border-4 rounded-md shadow-sm px-4 py-2 w-full m-2"
        placeholder="Entrez un benef mini..."
      />
      <select
        v-model="selectedCategory"
        @change="getTypes"
        class="border-yellow-500 border border-4 rounded-md shadow-sm px-4 py-2 w-full m-2"
      >
        <option disabled value="-1">Catégorie</option>
        <option
          v-for="category in categories"
          :key="category.categoryId"
          :value="category.categoryId"
        >
          {{ category.categoryName }}
        </option>
      </select>
      <select
        v-model="selectedType"
        class="border-yellow-500 border border-4 rounded-md shadow-sm px-4 py-2 w-full m-2"
      >
        <option disabled value="-1">Type</option>
        <option v-for="type in types" :key="type.typeId" :value="type.typeId">
          {{ type.name }}
        </option>
      </select>
    </div>

    <!-- Tableau -->
    <div
      class="relative overflow-x-auto max-h-[70vh] rounded-md w-auto mx-4 border border-4 border-yellow-500"
    >
      <table
        class="w-full max-h-[70vh] text-sm text-left text-gray-500 divide-x-2"
      >
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <!-- Générer dynamiquement les en-têtes de colonne avec boutons de filtre -->
            <template v-for="(column, index) in columns">
              <th
                v-if="index >= 2"
                :key="index"
                :class="{
                  'px-6 py-3 relative bg-gray-100': index % 2 === 0,
                  'px-6 py-3 relative bg-gray-300': index % 2 !== 0,
                }"
              >
                {{ column }}
                <button
                  @click="sortByColumn(index)"
                  class="ml-2 text-gray-400 absolute right-10 top-0 bottom-0 flex items-center"
                >
                  <svg
                    v-if="sortColumn === index && sortDirection === 'asc'"
                    class="h-4 w-4 inline"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                  <svg
                    v-else
                    class="h-4 w-4 inline"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </th>
            </template>
            <th
              v-if="deleteButton"
              :key="'delete'"
              :class="'px-6 py-3 relative bg-gray-100'"
            ></th>
            <th
              v-if="deleteButton"
              :key="'show'"
              :class="'px-6 py-3 relative bg-gray-300'"
            ></th>
          </tr>
        </thead>
        <tbody>
          <!-- Générer dynamiquement les lignes à partir des données triées -->
          <tr
            v-for="(row, rowIndex) in sortedRows"
            :key="rowIndex"
            class="bg-white border-b group-hover:bg-yellow-500 group-hover:text-black group"
            style="transition: background-color 0.3s, color 0.3s"
          >
            <template v-for="(cell, cellIndex) in row">
              <td
                v-if="
                  (!deleteButton || cellIndex < row.length - 1) &&
                  cellIndex >= 2
                "
                :key="cellIndex"
                :class="{
                  'px-6 py-4 font-medium text-gray-900 bg-white group-hover:bg-yellow-500 group-hover:text-black':
                    cellIndex % 2 === 0,
                  'px-6 py-4 font-medium text-gray-900 bg-gray-200 group-hover:bg-yellow-500 group-hover:text-black':
                    cellIndex % 2 !== 0,
                }"
              >
                <div
                  class="text-ellipsis overflow-hidden whitespace-nowrap max-w-64"
                >
                  {{ cell }}
                </div>
              </td>
            </template>
            <!-- Affiche le bouton de suppression si deleteButton est vrai -->
            <td
              v-if="deleteButton"
              class="group-hover:bg-yellow-500 group-hover:text-black"
            >
              <div
                class="text-ellipsis overflow-hidden whitespace-nowrap max-w-64"
              >
                <button
                  @click="deleteFavorite(row[row.length - 1])"
                  class="text-red-500 w-full"
                >
                  Supprimer
                </button>
              </div>
            </td>
            <td
              v-if="deleteButton"
              class="group-hover:bg-yellow-500 group-hover:text-black bg-gray-200"
            >
              <div
                class="text-ellipsis overflow-hidden whitespace-nowrap max-w-64"
              >
                <button
                  @click="showFavorite(row)"
                  class="text-yellow-600 w-full"
                >
                  Afficher
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    columns: Array,
    rows: Array,
    deleteButton: Boolean,
    refreshFunction: Function,
  },
  data() {
    return {
      sortColumn: null,
      sortDirection: "asc",
      searchQuery: "",
      benefQuery: "",
      categories: [],
      types: [],
      selectedCategory: -1,
      selectedType: -1,
    };
  },
  computed: {
    // Filtrer les lignes en fonction de la requête de recherche
    filteredRows() {
      const query = this.searchQuery.toLowerCase();
      return this.rows
        .filter((row) => {
          // Recherche uniquement dans la catégorie selectionné
          const category = row[0] !== "" ? parseFloat(row[0]) : -1;
          return (
            category === this.selectedCategory || this.selectedCategory == -1
          );
        })
        .filter((row) => {
          // Recherche uniquement dans le type selectionné
          const type = row[1] !== "" ? parseFloat(row[1]) : -1;
          return type === this.selectedType || this.selectedType == -1;
        })
        .filter((row) => {
          // Recherche uniquement dans la colonne "Nom"
          if (!this.searchQuery || this.searchQuery.length < 4) return true;
          const nom = row[2] ? row[2].toString().toLowerCase() : "";
          return nom.includes(query);
        })
        .filter((row) => {
          // Recherche uniquement dans la colonne "benef"
          if (isNaN(this.benefQuery) || this.benefQuery === "") return true;
          return this.getParsedFloat(row[7]) > this.benefQuery;
        })
        .map((row) => {
          // Formatage des nombres avec un espace entre les milliers et déplacement de % et K à la fin
          for (let i = 0; i < row.length; i++) {
            if (!isNaN(this.getParsedFloat(row[i]))) {
              let num = this.getParsedFloat(row[i]);
              let containsK = row[i].toString().includes(" K");
              let containsPercent = row[i].toString().includes("%");
              if (containsK) {
                row[i] = num.toLocaleString() + " K";
              } else if (containsPercent) {
                row[i] = num.toLocaleString() + " %";
              } else {
                row[i] = num.toLocaleString();
              }
            }
          }
          return row;
        });
    },
    // Tri des lignes filtrées
    sortedRows() {
      const copiedRows = [...this.filteredRows];
      if (this.sortColumn !== null) {
        copiedRows.sort((a, b) => {
          const aValue = a[this.sortColumn];
          const bValue = b[this.sortColumn];

          const parsePrice = (value) => {
            if (typeof value === "string") {
              const num = this.getParsedFloat(value);
              return isNaN(num) ? Number.POSITIVE_INFINITY : num;
            }
            return value;
          };

          const parsedAValue = parsePrice(aValue);
          const parsedBValue = parsePrice(bValue);
          if (aValue === "Non disponible" && bValue === "Non disponible") {
            return 0;
          } else if (aValue === "Non disponible") {
            return 1;
          } else if (bValue === "Non disponible") {
            return -1;
          } else if (isNaN(parsedAValue) && isNaN(parsedBValue)) {
            // Both values are non-numeric strings
            return this.sortDirection === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          } else if (isNaN(parsedAValue)) {
            // aValue is a non-numeric string
            return this.sortDirection === "asc" ? 1 : -1;
          } else if (isNaN(parsedBValue)) {
            // bValue is a non-numeric string
            return this.sortDirection === "asc" ? -1 : 1;
          } else {
            // Both values are numbers
            return this.sortDirection === "asc"
              ? parsedAValue - parsedBValue
              : parsedBValue - parsedAValue;
          }
        });
      }

      return copiedRows;
    },
  },
  methods: {
    getParsedFloat(number) {
      let tempNumber = number;
      if (typeof tempNumber === "string")
        tempNumber = number
          .replace(" K", "")
          .replace("%", "")
          .replace(/\s/g, "")
          .replace(",", ".");
      return parseFloat(tempNumber);
    },
    sortByColumn(columnIndex) {
      if (this.sortColumn === columnIndex) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnIndex;
        this.sortDirection = "asc";
      }
    },
    async deleteFavorite(itemId) {
      // Mettre à jour les données après la suppression de l'élément
      this.$emit("refresh-function", itemId.replaceAll(/\s/g, ""));
    },
    showFavorite(itemId) {
      console.log(itemId);
      /*const currentDomain = window.location.origin;
      const item = itemId.replaceAll(/\s/g, "");
      const url = `${currentDomain}/items/${item}`;
      
      // Rediriger l'utilisateur vers la nouvelle page
      window.open(url, "_blank");*/
    },
    async fetchCategories() {
      try {
        const response = await axios.get(
          "https://dofusapi.scuisond.com/categories"
        );
        this.categories = response.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async getTypes() {
      this.selectedType = -1;
      if (this.selectedCategory !== "") {
        try {
          const response = await axios.get(
            `https://dofusapi.scuisond.com/categories/${this.selectedCategory}/types`
          );
          this.types = response.data;
        } catch (error) {
          console.error("Error fetching types:", error);
        }
      } else {
        this.types = [];
      }
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>
